<template>
  <div>
    <div class="subscriber">
      <div class="subscriber-profile-info">
        <div class="subscriber-profile-info-alpha">
          <div class="subscriber-profile-info-alpha-profileImage">
            <img
              class="subscriber-profile-info-alpha-profileImage-image"
              v-if="subscriberProfileDetails ? subscriberProfileDetails.picture : subscriberDetails ? subscriberDetails.picture : null"
              :src="subscriberProfileDetails ? subscriberProfileDetails.picture : subscriberDetails ? subscriberDetails.picture : null"
              alt
            />
            <img class="subscriber-profile-info-alpha-profileImage-image" v-else :src="displayPic" alt />
          </div>
          <p class="subscriber-profile-info-alpha-subscribername">
          
            {{ getSubscriberProfileName }}
            <span v-if="subscriberid === profileid">
              <img src="@/assets/icons/Admin.svg" alt />
            </span>
          </p>
          <p v-if="subscriberDetails.email" class="subscriber-profile-info-alpha-subscriberemail">
            {{ subscriberDetails.email }}
          </p>

          <br v-if="subscriberDetails.mobileno" />

          <p v-if="subscriberDetails.mobileno" class="subscriber-profile-info-alpha-subscriberemail">
              {{ subscriberDetails.mobileno }} 
          </p>
        </div>

        <div class="subscriber-profile-info-beta">
          <div class="subscriber-profile-info-beta-common location">
            <img src="@/assets/icons/Location.svg" alt />
            <p class="subscriber-profile-info-beta-common-info">
              {{ $t(subscriberDetails.subscribercountry) }}
            </p>
          </div>

          <div class="subscriber-profile-info-beta-common gender" v-if="getProfileGender">
            <img src="@/assets/icons/Name.svg" alt />
            <p class="subscriber-profile-info-beta-common-info">
              {{ $t(getProfileGender) }}
            </p>
          </div>

          <div class="subscriber-profile-info-beta-common dob" v-if="subscriberAgeRange">
            <img src="@/assets/icons/Calender-1.svg" alt />
            <p dir="ltr" class="subscriber-profile-info-beta-common-info no-padding">
              <span>{{ subscriberAgeValue }}</span>
              {{ $t("Years") }}
            </p>
          </div>
        </div>
        <div class="subscriber-profile-info-mutable">
            <!-- <div class="profile-option-img"  @click="PreferredLangPopup()">
              <div >
                <img class="profile-order"src="@/assets/icons/preflang.svg" alt />
              </div>
              <p class="popup-methods">{{ $t("preferred language") }}</p>
            </div> -->
            <div class="profile-option-img">
              <div >
                <img class="profile-order" src="@/assets/icons/editprofile.svg" alt />
              </div>
            <p class="popup-methods" @click="editSubscriber('Update')"> {{ $t("edit profile") }} </p>  
          </div>
          <div class="profile-option-img">
            <div>
                <img class="profile-order" src="@/assets/icons/password.svg" alt />
              </div>
            <p v-if="showChangePasswordButton" class="popup-methods" @click="subscriberDetails.setpassword === 'NO' ? setNewPassword() : toggleChangePassword(true)">
          {{ subscriberDetails.setpassword === 'NO' ? $t('Set Password') : $t('change password') }} </p>
          </div>
          </div>
         
      </div>
      <transition>
        <div>
          <ChangePassword v-if="showChangePassword" :closePopup="() => toggleChangePassword(false)"></ChangePassword>
          <profilePopup
            v-if="showProfilePopup"
            :popupType="popupType"
            :detail="profileDetail ? profileDetail : {}"
            @refreshList="getUserInfo"
            :closePopup="() => toggleProfilePopup(false)"
          ></profilePopup>
          <confirmation
            v-if="showConfirmation"
            :deleteInfo="deleteInfo"
            @deleteConfirm="getUserInfo"
            @refreshProfilesList="getUserInfo"
            :closePopup="() => toggleConfirmation(false)"
          ></confirmation>
        </div>
      </transition>
    </div>

    <!-- The Manage Profile Container -->
    <div v-if="subscriberid === profileid && appConfig.featureEnabled.isMultipleProfileEnabled" :class="[
          localDisplayLang === 'ara' ? 'manageProfileContainerArabic' : 'manageProfileContainer',
        ]" 
    >
      <div class="manageProfiles">
        <div class="align-space-between" >
          <p class="manageProfilesText">{{$t("manage profiles")}}</p>
          <div
              class="icon-container"
              @click="addProfile"
              v-if="showAddProfileButton"
              >
              <img src="@/assets/icons/addWhite.png"  alt/>
              </div>
        </div>
        <div v-for="userProfile in profilesList" :key="userProfile.profileid" class="profiles">
          <div class="profileDetails">
            <img v-if="!userProfile.picture && userProfile.kidsmode === 'YES'" src="@/assets/icons/ProfileDp.png" alt="kids profileImage" class="profileImage" />
            <img v-else-if="userProfile.picture" :src="userProfile.picture" alt="" class="profileImage" />

            <img v-else src="@/assets/icons/ProfileDp.png" alt="Hello" class="profileImage" />
            <div>
              <p style="height:16px" class="profileName">{{ userProfile.profilename }}</p>
              <p v-if="subscriberid === userProfile.profileid" class="profileBadge">{{ $t("admin") }}</p>
							<p v-if="userProfile.kidsmode === 'YES'" class="profileBadge">{{ $t("kids") }}</p>
							<!-- <p v-if="subscriberid === userProfile.profileid" class="profileBadge">{{ $t("admin") }}</p> -->
            </div>
          </div>
          <div>
            <div class="icon-parent">
              <div
              class="icon-container"
             @click="editProfile(userProfile)"
              >
              <img src="@/assets/icons/editWhite.png"  alt/>
              </div>
              <div
              class="icon-container"
              v-if="subscriberid !== userProfile.profileid" @click="deleteProfile(userProfile)"
              >
              <img src="@/assets/icons/deleteWhite.png"  alt/>
              </div>
            </div>
         
          </div>
        </div>
      </div>
      <div >
        <p class="delete-myaccount" @click="deleteSubscriber">{{$t("Delete Account?")}}</p>
      </div>
    </div>

    <DeleteAccount 
    v-if="showDeleteModal"
     @closePopup="closeDeleteModal"
    />

    <NewPassword
    v-if="setPasswordModal"
    @close-use-password="getCloseCB"
    />
   
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import { mapActions, mapGetters } from "vuex";
import { _providerId, _projectName } from "@/provider-config.js";
import { setUserPropertiesFunc } from '@/utilities';

export default {
  data() {
    return {
      subscriberDetails: null,
      showChangePassword: false,
      subProfiles: [],
      displayPic: require("@/assets/icons/Generic.png"),
      editIcon: require("@/assets/icons/Edit.svg"),
      deleteIcon: require("@/assets/icons/Trash.svg"),
      profileSectionLoader: false,
      isSubProfiles: false,
      showProfilePopup: false,
      showConfirmation: false,
      popupType: null,
      profile: null,
      subscriber: null,
      profileDetail: null,
      deleteInfo: null,
      subscriberAgeRange: null,
      providerUniqueId: _providerId,
      providerName: _projectName,
      localDisplayLang:"",
      profilesList: null,
      subscriberProfileDetails: null,
      loginType: null,
      subscriberAgeValue: null,
      subscriptionDetails: null,
      noOfUserProfilesLimit: 5,
      showDeleteModal:false,
      setPasswordModal:false
      
    };
  },

  computed: {
    ...mapGetters(["subscriberid", "profileid"]),

    showChangePasswordButton() {
      if(this.profileid === this.subscriberid && this.loginType !== "Google" && this.loginType !== 'FB') {
        return true;
      }
    },

    showAddProfileButton() {
      if (
        this.subscriberid === this.profileid &&
        this.profilesList &&
        this.profilesList.length < this.noOfUserProfilesLimit
      ) {
        return true;
      } else {
        return false;
      }
    },
    getSubscriberProfileName() {

      if (this.subscriberProfileDetails && this.subscriberProfileDetails.profilename && this.subscriberProfileDetails.profilename.length > 15) {
        return `${this.subscriberProfileDetails.profilename.substring(0, 15)}...`;
      } else if (this.subscriberProfileDetails && this.subscriberProfileDetails.profilename) {
        return this.subscriberProfileDetails.profilename;
      } else if (this.subscriberDetails.profilename && this.subscriberDetails.profilename.length > 15) {
        return `${this.subscriberDetails.profilename.substring(0, 15)}...`;
      } else {
        return this.subscriberDetails.profilename;
      }
    },

    getProfileGender () {

      if (this.subscriberProfileDetails && this.subscriberProfileDetails.gender) {
        return this.subscriberProfileDetails.gender;
      } 
      
      else if(this.subscriberDetails && this.subscriberDetails.gender) {
        return this.subscriberDetails.gender;
      } 
    }
  },

  created() {
  this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
  this.getUserInfo();

  this.getCurrentProfileDetails();
 
},


  mounted() {
    window.addEventListener("localstorage-updated", (event) => {
      this.subscriberDetails = JSON.parse(event.detail.storage).data;
      let subscriberAge = parseInt(this.subscriberDetails.age);

      this.getCurrentProfileDetails();

      this.getSubscriberAgeRange(subscriberAge);
    });
  },
  methods: {
    ...mapActions(["listProfiles", "deleteProfile"]),
    getCloseCB (cb) {
      this.setPasswordModal = cb;
      this.getUserInfo();

    },
    setNewPassword() {
     this.setPasswordModal = true;
    },

    deleteSubscriber(){
     this.showDeleteModal=true;
     document.body.style.overflow = "hidden";    
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
      document.body.style.overflow = "auto";  
    },
    getUserInfo() {
      this.isSubProfiles = false;
      if (localStorage.getItem("subscriberDetails")) {
        this.subscriberDetails = JSON.parse(localStorage.getItem("subscriberDetails")).data;
      }

      this.loginType = localStorage.getItem("login-payload") ? localStorage.getItem("login-payload") : ""

      if (localStorage.getItem("subscriptionDetails")) {
        this.subscriptionDetails = JSON.parse(localStorage.getItem("subscriptionDetails"));
      }

      // if(this.subscriberid === this.profileid) {
        this.listUserProfiles();
      // }

      this.getCurrentProfileDetails();

      setUserPropertiesFunc(this.subscriberDetails, this.subscriptionDetails);
    },

    getCurrentProfileDetails() {
      this.subscriberProfileDetails = localStorage.getItem("subscriberProfileDetails")
        ? JSON.parse(localStorage.getItem("subscriberProfileDetails"))
        : null;

      const ageOfSubscriber = this.subscriberProfileDetails ? this.subscriberProfileDetails.age : "";
      if(ageOfSubscriber) {
        this.getSubscriberAgeRange(parseInt(ageOfSubscriber));
        this.subscriberAgeValue = parseInt(ageOfSubscriber);
      }
    },

    listUserProfiles() {
      this.listProfiles()
        .then((data) => {
          this.profilesList = data.data.data;
          localStorage.setItem("profile-list",JSON.stringify(this.profilesList));

          this.profilesList.forEach(profile => {
            if(profile.profileid === this.profileid) {
              this.subscriberProfileDetails = profile;

              console.log("subscriberProfileDetails" , this.subscriberProfileDetails);

              this.subscriberAgeValue = parseInt(this.subscriberProfileDetails.age);

              localStorage.setItem("subscriberProfileDetails", JSON.stringify(profile));
            }
          })
        })
        .catch((err) => {
          console.log("Error While fetching profiles -> ", err);
        });
    },

    getSubscriberAgeRange(data) {
      switch (true) {
        case data < 13:
          this.subscriberAgeRange = "<13";
          console.log("subscriberAgeRange", this.subscriberAgeRange);
          break;
        case data < 18:
          this.subscriberAgeRange = "13-17";
          console.log("subscriberAgeRange", this.subscriberAgeRange);
          break;
        case data < 31:
          this.subscriberAgeRange = "18-30";
          console.log("subscriberAgeRange", this.subscriberAgeRange);
          break;
        case data < 45:
          this.subscriberAgeRange = "31-45";
          console.log("subscriberAgeRange", this.subscriberAgeRange);
          break;
        case data > 45:
          this.subscriberAgeRange = "45+";
          console.log("subscriberAgeRange", this.subscriberAgeRange);
          break;
        default:
          break;
      }
    },

    switchProfile(profile) {
      console.log("profile", profile);
      if (profile.profilepin === "ENABLED") {
        eventBus.$emit("switchProfile", profile);
      } else {
        eventBus.$emit("enableOverlayLoader", true);

        let payload = {
          profileid: profile.profileid,
        };
        eventBus.$emit("switch-profile", payload);
      }
    },

    editSubscriber(value) {
      this.profileDetail = this.subscriberProfileDetails ? this.subscriberProfileDetails : this.subscriberDetails;
      this.toggleProfilePopup(true, value);
    },

    editProfile(data) {
      this.profileDetail = data;

      this.toggleProfilePopup(true, 'Update');
    },

    deleteProfile(data) {
      this.toggleConfirmation(true);
      this.deleteInfo = data;
    },

    addProfile(){
      let currentLanguage = this.getCurrentLanguageUrlBase();
      this.$router.push({name: "addProfileCheck", params: { lang: currentLanguage}})
    },

    toggleChangePassword(state) {
      this.showChangePassword = state;
    },

    toggleConfirmation(state) {
      this.showConfirmation = state;
    },

    toggleProfilePopup(state, value) {
      if (!state) {
        this.profileDetail = {};
      }
      this.popupType = value;
      this.showProfilePopup = state;
    },

    PreferredLangPopup() {
      eventBus.$emit('preferred-lang-visibility', true);
    }
  },
  components: {
    NewPassword: () =>
      import(/* webpackChunkName: "SetPassword" */ "@/components/Popups/ProfilePopups/NewPassword.vue"),
    ChangePassword: () =>
      import(/* webpackChunkName: "header" */ "@/components/Popups/ProfilePopups/ChangePassword.vue"),
    profilePopup: () => import(/* webpackChunkName: "header" */ "@/components/Popups/ProfilePopups/profilePopup.vue"),
    confirmation: () => import(/* webpackChunkName: "confirmation" */ "@/components/Popups/confirmation.vue"),
    DeleteAccount: () => import(/* webpackChunkName: "confirmdelete" */ "@/components/Popups/ProfilePopups/DeleteAccount.vue"),
    OtpModal: () => import("@/components/utils/OtpModal.vue"),
  },
  mixins: [Utility, googleAnalytics],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
.subscriber {
  background-color: $clr-sbscrberpg-bg;
  font-family: $font-regular;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  
  &-profile-info {
    width: 100%;
    padding: 0.9rem 1.8rem;

    &-alpha {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1rem;

      &-profileImage {
        margin-bottom: 0.6rem;
        width: 90px;
        display: flex;
        justify-content: center;
        align-items: center;

        &-image {
          width: 80%;
          height: 70px;
          border-radius: 50%;
        }
      }
      
      &-subscribername {
        color: $clr-sbscbername;
        display: flex;
        font-weight: 600;
        line-height: 25px;
        margin-bottom: 0.3rem;
      }
      
      &-subscriberemail {
        color: $clr-sbsciberemail-txt;
        font-size: 0.9rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        width: 200px;
        text-align: center;
      }
    }

    &-beta {
      display: flex;
      color: $clr-sbscber-countrycode-txt;
      opacity: 0.5;
      line-height: 30px;
      margin: 1.2rem 0rem;
      width: 100%; 
     
      &-common {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        
        img {
          margin-right: 8px;
          width: 15px;
          height: 15px;
        }
        
        &-info {
          font-size: 0.8rem;
          padding-right: 8px;
        }
      }
    }

    &-mutable {
      justify-content: space-between;
      font-size: 0.8rem;
      color: #ffffff;
      cursor: pointer;
      margin: 10px 0px;

      .profile-option-img {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 10px;
        border-radius: 5px;
        transition: background-color 0.3s ease, transform 0.3s ease;
        white-space: nowrap;
        line-height: 20px;
      }

      .profile-order {
        width: 13px;
        height: 14px;
        transition: transform 0.3s ease;
      }

      .popup-methods {
        opacity: 1;
        font-size: 0.75rem;
        transition: color 0.3s ease;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .profile-option-img:hover {
        background: linear-gradient(90deg, #9000B9 0%, #EA00FF 100%);
        transform: scale(1.05);
      }

      .profile-option-img:hover .popup-methods {
        color: $clr-sbscriber-edit-changepaswrd-hover-txt;
      }

      .profile-option-img:hover .profile-order {
        transform: scale(1.1);
      }
    }
  }

  &-related {
    padding: 1.2rem 1.8rem;
    background-color: $clr-dark-gd5;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    &-placeholder {
      &-card {
        display: flex;
        align-items: center;
        background-color: $clr-dark-gd4;
        border-radius: 10px;
        margin: 0.5rem 0;
        padding: 0.5rem;
        &-icon {
          width: 20%;
          height: 40px;
          border-radius: 50%;
          background-color: #0d0d0d;
          overflow: hidden;
          margin-right: 10px;
        }
        &-info {
          &-title {
            height: 10px;
            width: 85px;
            background-color: #0d0d0d;
            margin-bottom: 5px;
            overflow: hidden;
          }
          &-type {
            height: 10px;
            width: 40px;
            background-color: #0d0d0d;
            overflow: hidden;
          }
        }
      }
    }
    &-addprofile {
      &-title {
        font-size: 1rem;
        color: #efeff4;
        font-weight: bold;
        opacity: 0.5;
        font-family: $font-regular;
      }
      &-btn {
        width: 50%;
        margin: 20px 0;
        padding: 0.4rem;
        font-size: 0.7rem;
      }
    }
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 0.5rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid $clr-dark-gd11;
      &-title {
        font-size: 0.7rem;
        color: $clr-light-gd3;
        font-weight: bold;
        opacity: 0.5;
        line-height: 30px;
        &:last-child {
          &:hover {
            color: $btn-clr;
            // color: $clr-light-gd2; // for nammaflix
            opacity: 1;
          }
        }
      }
      .cursor {
        cursor: pointer;
      }
    }
    &-profiles {
      &-list {
        display: flex;
        align-items: center;
        line-height: 15px;
        background-color: $clr-dark-gd4;
        border-radius: 10px;
        margin: 0.5rem;
        padding: 1rem;
        &-switch {
          display: none;
          padding: 0.1rem 0.5rem;
          font-size: 0.5rem;
          margin-top: 5px;
          width: 60%;
        }
        &:hover {
          background-color: $clr-dark-gd11;
          .action-btns {
            display: block;
          }
          .enable-switch {
            display: block;
          }
        }
        &-profileIcon {
          width: 45px;
          .sub-imgs {
            width: 100%;
            height: 38px;
            border-radius: 50%;
          }
        }
        &-profileInfo {
          width: 100%;
          padding-left: 0.7rem;
          &-list {
            display: flex;
            justify-content: space-between;
            width: 100%;
            &-container {
              &-fullname {
                font-size: 0.8rem;
                color: $clr-light-gd3;
                font-weight: bold;
              }
              &-email {
                font-size: 0.6rem;
                color: $clr-light-gd3;
                font-weight: bold;
                opacity: 0.5;
              }
            }
          }
          .action-btns {
            display: none;
          }
          &-actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            img {
              width: 27px;
              height: 15px;
              cursor: pointer;
              // display: none;
            }
          }
        }
      }
    }
  }
}
.delete-myaccount{
    margin-top: 2.2rem;
    background: $clr-mainhead-bg;
    color: #fff;
    transform: scale(0.98);
    border: 2px solid transparent;
    font-size: 1rem;
    padding: 0.6rem;
    line-height: 1;
    font-weight: 600;
    cursor: pointer;
    overflow: hidden;
    transition: all .2s ease-in-out;
    white-space: nowrap;
    text-transform: none;
    height: 40px;
    border-radius: 0.3rem;
    display: flex;
    justify-content: center;
    &:hover {
          opacity: 1;
          background: #fff;
          color:#EA00FF;
        }
}
.manageProfileContainer {
  margin: 1rem 0;
  height: fit-content;
  border-radius: 1rem;
  padding: 1rem;
  background-color: #212121;
  .manageProfiles {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .manageProfilesText {
      color: $clr-managePrfile-txt;
      font-family: $font-regular;
      font-size: 1rem;
      letter-spacing: 0;
      line-height: 12px;
      font-weight: 600;
      padding: 1rem;
    }

    .profiles {
      margin: 0.75rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      .profileDetails {
        display: flex;
        .profileImage {
          width: 40px;
          height: 40px;
					border-radius: 50%;
        }
        .profileName {
          margin: 0.25rem;
          color: $clr-profilebadge-txt;
          margin-left: 1.5rem;
          font-family: $font-regular;
          font-size: 0.8rem;
          letter-spacing: 0;
          line-height: 12px;
          font-weight: 600;
          white-space: nowrap; 
          width: 95px; 
          overflow: hidden;
          text-overflow: ellipsis; 
  
           }

        .profileBadge {

          margin-top: 0.8rem;
          color: $clr-profilebadge-txt;
          margin-left: 1.5rem;
          font-family: $font-regular;
          letter-spacing: 0;
          line-height: 12px;
          font-weight:300;
          font-size: 0.75rem;

        }
      }

      .editProfile {
        margin-bottom: 1.2rem;
        color: $clr-profilename-delte-txt;
        margin-left: 1.5rem;
        font-family: $font-regular;
        font-size: 0.8rem;
        letter-spacing: 0;
        line-height: 12px;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
}

.manageProfileContainerArabic {
  margin: 1rem 0;
  height: fit-content;
  border-radius: 1rem;
  padding: 1rem;
  background-color: #212121;
  .manageProfiles {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .manageProfilesText {
      color:$clr-managePrfile-txt;
      font-family: $font-regular;
      font-size: 1rem;
      letter-spacing: 0;
      line-height: 12px;
      font-weight: 600;
      padding: 1rem;
    }

    .profiles {
      margin: 0.75rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      .profileDetails {
        display: flex;
        .profileImage {
          width: 40px;
          height: 40px;
					border-radius: 50%;
        }
        .profileName {
          margin: 0.25rem;
          color: $clr-dark-gd10;
          margin-right: 1.5rem;
          font-family: $font-regular;
          font-size: 0.8rem;
          letter-spacing: 0;
          line-height: 12px;
          font-weight: 600;
        }

        .profileBadge {

          margin-top: 0.8rem;
          color: $clr-dark-gd10;
          margin-right: 1.5rem;
          font-family: $font-regular;
          letter-spacing: 0;
          line-height: 12px;
          font-weight:300;
          font-size: 0.75rem;

        }
      }

      .editProfile {
        margin-bottom: 1.2rem;
        color: $clr-dark-gd10;
        margin-right: 1.5rem;
        font-family: $font-regular;
        font-size: 0.8rem;
        letter-spacing: 0;
        line-height: 12px;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
}


@media only screen and (max-width: 576px) {
  .profileName {
      overflow: visible !important;
        }
   .subscriber-profile-info-alpha-subscriberemail{
      overflow: visible !important;
   }
           
  .subscriber {
    margin-bottom: 20px;
    &-related {
      &-profiles {
        &-list {
          &-switch {
            display: block;
            padding: 0.2rem;
            font-size: 10px;
          }
          &-profileIcon {
            width: 60px;
            .sub-imgs {
              height: 60px;
            }
          }
          &-profileInfo {
            &-list {
              &-container {
                &-fullname {
                  font-size: 1rem;
                }
              }
            }
          }
        }
        .action-btns {
          display: block;
        }
        &-actions {
          img {
            width: 15px;
            height: 15px;
            cursor: pointer;
            margin-right: 5px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscriber {
    margin-bottom: 20px;
  }
    .profileName {
          overflow: visible !important;
        }
    .subscriber-profile-info-alpha-subscriberemail{
      overflow: visible !important;
   }
}

.icon-parent{
  display: flex;
  gap: 5px;
}

.icon-container{
  width: 24px;
  height: 24px;
  display: flex;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease-in;
  cursor: pointer;
  img {
    max-width: 16px !important;
    max-height: 16px !important;
    height: 12px;
    width: 12px;
  }
}

.icon-container:hover{
  background-color: $primary-btn-color;
}

.align-space-between{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

</style>
